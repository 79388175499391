export enum FeatureFlags {
    RadiusSection = 'RADIUS_SECTION',
    AnnualSalesRange = 'ANNUAL_SALES_RANGE',
    NearMe = 'NEAR_ME',
    PurchasePotentialFilter = 'FF_PURCHASE_POTENTIAL',
    ConfidenceLevelChart = 'FF_CONFIDENCE_LEVEL_CHART',
    EstMealsPerDay = 'FF_EST_MEALS_PER_DAY',
    TagsExport = 'FF_TAGS_EXPORT',
    ResultsMap = 'FF_RESULTS_MAP',
    ProfileTags = 'FF_PROFILE_TAGS',
    LodgingNav = 'FF_LODGING_NAV',
    NumberOfRooms = 'FF_NUMBER_OF_ROOMS',
    HotelClass = 'FF_HOTEL_CLASS',
    TagsFilter = 'FF_TAGS_FILTER',
    GlobalMenu = 'FF_GLOBAL_MENU',
    ExportExcel = 'FF_EXCEL_EXPORT',
}

export function useFeatureFlag(flag: string): boolean {
    return process.env[`REACT_APP_${flag}`] === 'true';
}
