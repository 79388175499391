import { FC, useEffect, useMemo } from 'react';

import { useLocale } from '@core/utils/locale';
import { RoutesEnum } from '@core/enums/RoutesEnum';
import { GradientColorsEnum } from '@core/enums/gradientEnum';
import { CommonPhrasesEnum, OperatorProfilePhrasesEnum } from '@core/enums/localeEnum';
import { IconFilterGeneral, IconInformation, IconProfileChain } from '@assets/icons';

import Tabs from '@components/utility/Tabs/Tabs';
import PageTitle from '@components/ui/PageTitle/PageTitle';
import Breadcrumbs from '@components/ui/Breadcrumbs/Breadcrumbs';
import Text, { TextVariant } from '@components/ui/Typography/Text';
import Widget, { WidgetType } from '@components/utility/Widget/Widget';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';
import BadgeIcon, { BadgeIconSizeEnum } from '@components/utility/BadgeIcon/BadgeIcon';

import './OperatorProfile.styles.scss';
import Details from './Details/Details';
import { useOperatorProfile } from './useOperatorProfile';
import OperatorProfileLocation from './OperatorProfileLocation/OperatorProfileLocation';
import { EntityTypes, mapApiEntityTypes, renderTags } from './OperatorProfile.helpers';
import { OperatorDelivery, OperatorLocationDetails, OperatorSocialMedia } from '@graphql/generated/graphql';
import Tooltip from '@components/utility/Tooltip/Tooltip';
import InformationTooltip from '@components/utility/Tooltip/InformationTooltip';
import { useSkeleton } from '@components/utility/Skeleton/SkeletonProvider';
import { OperatorProfileSkeletonComponent } from '@components/utility/Skeleton/SkeletonComponents';
import Menu from './Menu/Menu';
import { FeatureFlags, useFeatureFlag } from '@core/hooks/useFeatureFlag';
import useMenu from '@core/api/useGlobalMenu';
import { CountryCodesEnum } from '@core/enums/flagsEnum';

const OperatorProfile: FC = () => {
    const { isSkeleton } = useSkeleton();
    const { t } = useLocale();
    const {
        data,
        loading,
        services
    } = useOperatorProfile();

    const {
        data: menuData,
        doFetch
    } = useMenu(data?.operator_identifier ?? '');

    useEffect(() => {
        if (data?.operator_identifier) {
            doFetch();
        }
    }, [data?.operator_identifier, doFetch]);

    const isGlobalMenuEnabled = useFeatureFlag(FeatureFlags.GlobalMenu);

    const nonCommercialSegmentOnly = 'S04.';
    const entityType = data?.parent_chain?.id
        ? data?.segment_code?.startsWith(nonCommercialSegmentOnly)
            ? EntityTypes.DISTRICT_SYSTEM_UNIT
            : EntityTypes.CHAIN
        : EntityTypes.UNIT;
    const entityTypeChainOrDistrict: boolean =
        entityType === EntityTypes.DISTRICT_SYSTEM_UNIT || entityType === EntityTypes.CHAIN;

    const getLocalizedConfidenceLevel = (confidenceLevel: string | undefined) => {
        const confidenceLevelMap: { [key: string]: string } = {
            high: t(OperatorProfilePhrasesEnum.High),
            medium: t(OperatorProfilePhrasesEnum.Medium),
            low: t(OperatorProfilePhrasesEnum.Low),
        };

        return confidenceLevel ? confidenceLevelMap[confidenceLevel.toLowerCase()] || '--' : '';
    };

    const tabsContent = useMemo(() => {
        return [
            {
                label: <span data-testid="headingDetails">{t(OperatorProfilePhrasesEnum.Details)}</span>,
                content: <Details data={data} services={services} />,
            },
            ...(isGlobalMenuEnabled && menuData && menuData.length > 0
                ? [
                    {
                        label: <span data-testid="headingMenu">{t(OperatorProfilePhrasesEnum.Menu)}</span>,
                        content: (
                            <Menu menuData={menuData} countryCode={data.location?.country as CountryCodesEnum} />
                        ),
                    },
                ]
                : []),
        ];
    }, [t, data, services, isGlobalMenuEnabled, menuData]);

    if (loading && isSkeleton) {
        return <OperatorProfileSkeletonComponent />;
    }

    return (
        <div className="OperatorProfile">
            <Breadcrumbs
                breadcrumbs={[
                    {
                        label: t(OperatorProfilePhrasesEnum.SearchResults),
                        link: RoutesEnum.OperatorTargetingResults,
                    },
                ]}
            />
            <PageTitle title={data?.business_name ?? ''} />

            <Widget type={WidgetType.Section}>
                <div className="OperatorProfile__header">
                    <section className="OperatorProfile__header-content">
                        <div className="OperatorProfile__profile-info">
                            <BadgeIcon
                                icon={entityTypeChainOrDistrict ? <IconProfileChain /> : <IconFilterGeneral />}
                                baseColor={GradientColorsEnum.ORANGE}
                                size={BadgeIconSizeEnum.Large}
                            />
                            <Heading testId="EntityType" variant={HeadingVariant.H4}>
                                {t(mapApiEntityTypes[entityType])}
                            </Heading>

                            <Text
                                testId="textOperatorIdentifier"
                                style={{
                                    color: '#232020',
                                    opacity: 0.65,
                                }}
                            >
                                ID: {data?.operator_identifier}
                            </Text>

                            {entityTypeChainOrDistrict && data?.parent_chain?.number_of_units && (
                                <div className="OperatorProfile__profile-links">
                                    <Text testId="textNumberOfUnits" variant={TextVariant.B2}>
                                        {data?.parent_chain?.number_of_units} {t(OperatorProfilePhrasesEnum.Units)}
                                    </Text>
                                </div>
                            )}
                        </div>
                        <div className="OperatorProfile__profile-tags">
                            {renderTags(data?.channel, data?.segment, data?.cuisine ? data?.sub_cuisine : null)}
                        </div>
                    </section>
                    <div className="OperatorProfile__mobile-divider" />
                    <section className="OperatorProfile__header-content">
                        <div>
                            <div className="OperatorProfile__heading-description" data-testid="textConfidenceLevel">
                                {t(OperatorProfilePhrasesEnum.ConfidenceLevel)}
                                <Tooltip
                                    content={
                                        <InformationTooltip
                                            title={t(OperatorProfilePhrasesEnum.ConfidenceLevel)}
                                            description={t(CommonPhrasesEnum.ConfidenceLevelToolTipDesc)}
                                        />
                                    }
                                    placement="bottom"
                                >
                                    <div>
                                        <IconInformation />
                                    </div>
                                </Tooltip>
                            </div>
                            <Heading
                                variant={HeadingVariant.H2}
                                testId="ConfidenceLevelValue"
                                style={{
                                    lineHeight: '22px',
                                    letterSpacing: '1.1px',
                                }}
                            >
                                {getLocalizedConfidenceLevel(data?.confidence_level ?? '--')}
                            </Heading>
                        </div>
                    </section>
                </div>
            </Widget>
            <OperatorProfileLocation
                operatorName={data?.business_name ?? ''}
                location={data?.location as OperatorLocationDetails}
                website={data?.website}
                phone={data?.phone}
                email={data?.general_email}
                delivery={data?.delivery as OperatorDelivery[]}
                socialMedia={data?.social_media as OperatorSocialMedia[]}
                workingHours={data?.operating_hours_raw}
            />

            <Tabs tabsContent={tabsContent} />
        </div>
    );
};

export default OperatorProfile;
