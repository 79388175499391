import Widget, { WidgetType } from '@components/utility/Widget/Widget';
import Tabs, { TabsVariant } from '@components/utility/Tabs/Tabs';
import { useMemo, useState } from 'react';
import Card from '@components/utility/Card/Card';
import './Menu.styles.scss';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';
import { generateAnchorId } from '@core/utils/string';
import { IconDoubleArrow } from '@assets/icons';
import Button from '@components/utility/Button/Button';
import { MenuResponse, MenuItem } from '@core/api/useGlobalMenu';
import { CountryCodesEnum } from '@core/enums/flagsEnum';
import { formatPrice } from '@core/utils/currency';

const transformMenuDataToTabs = (menuData: MenuResponse) => {
    const primaryHeader = menuData[0].headers?.[0].headers?.[0];
    if (!primaryHeader) return [];

    return (
        primaryHeader.headers?.map((subHeader) => {
            const anchorId = generateAnchorId(subHeader.value, 'menu-tab-');
            return {
                label: <span data-testid={`heading${subHeader.value}`}>{subHeader.value}</span>,
                title: subHeader.value,
                anchorId,
                content: null,
                onClick: () => {
                    const element = document.getElementById(anchorId);
                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                    }
                },
            };
        }) || []
    );
};

const Menu = ({
    menuData,
    countryCode
}: { menuData: MenuResponse; countryCode: CountryCodesEnum }) => {
    const [resetActiveTab, setResetActiveTab] = useState(false);
    const tabsContent = useMemo(() => (menuData ? transformMenuDataToTabs(menuData) : []), [menuData]);

    const tabAnchorId = generateAnchorId('menu-tabs-anchor');
    const scrollToFirstTab = () => {
        const firstTab = document.getElementById(tabAnchorId);
        if (firstTab) {
            firstTab.scrollIntoView({ behavior: 'smooth' });
        }
        setResetActiveTab(true);
    };
    return (
        <>
            <Widget type={WidgetType.Section} className="MenuTabs">
                <div id={tabAnchorId}>
                    <Tabs
                        tabsContent={tabsContent}
                        resetActiveTab={resetActiveTab}
                        variant={TabsVariant.HorizontalScroll}
                    />
                </div>

                <div className="MenuTabs__content">
                    {tabsContent.map((tab, index) => {
                        const menuItems = menuData?.[0]?.headers?.[0]?.headers?.[0]?.headers?.[index]?.items;

                        if (!menuItems || menuItems.length === 0) {
                            return null;
                        }

                        return (
                            <div key={tab.anchorId} className="MenuTabs__section" id={tab.anchorId}>
                                <span className="MenuTabs__name">
                                    <Heading testId="headingMenuSubHeader" variant={HeadingVariant.H3}>
                                        {tab.title && <div className="Card__name">{tab.title}</div>}
                                    </Heading>
                                </span>
                                <div className="MenuTabs__section__items">
                                    {menuItems.map((item: MenuItem, itemIndex: number) => (
                                        <div className="MenuTabs__section__item" key={`${item.name}-${itemIndex}`}>
                                            <Card
                                                name={item.name}
                                                description={item.description}
                                                price={formatPrice(countryCode, item.price as number) ?? ''}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Widget>
            {tabsContent.length > 0 && (
                <Button onClick={scrollToFirstTab} className="MenuTabs__back-to-top">
                    <IconDoubleArrow className="Collapse__double-arrow" />
                </Button>
            )}
        </>
    );
};

export default Menu;
