import { RoutesEnum } from '@core/enums/RoutesEnum';
import { LocalesEnum } from '@core/enums/localeEnum';
import { CountryCodesEnum } from '@core/enums/flagsEnum';
import { ID } from '@core/models';

export function equalInLC(string1?: string, string2?: string) {
    if (string1 === string2) return true;
    if (!string1 || !string2) return false;
    return string1.toLowerCase() === string2.toLowerCase();
}

export function prepareForMatching(str: string) {
    return str.trim().replace(/\s+/gm, ' ').toLowerCase();
}

export function containsSubstring(str: string, search: string) {
    return prepareForMatching(str).includes(prepareForMatching(search));
}

export function getCountryCodeFromLocale(locale: LocalesEnum): CountryCodesEnum {
    return locale.split('_')[1] as CountryCodesEnum;
}

export function replaceRouteParam(route: RoutesEnum, routeParamKey: string, param: string): string {
    return route.replace(routeParamKey, param);
}

export function findPatternIndex(inputString: string, pattern: string): number | null {
    const regex = new RegExp(pattern, 'i'); // 'i' flag for case-insensitivity
    const match = regex.exec(inputString);

    if (match) {
        return match.index;
    }
    return inputString.search(pattern);
}

export function capitalize(str: string, capsAll?: boolean) {
    return capsAll
        ? str.toUpperCase()
        : str
            .split(' ')
            .map((word) => {
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            })
            .join(' ');
}

export function truncateString(str: string, maxLength: number): string {
    if (str.length > maxLength) {
        return str.slice(0, maxLength) + '...';
    } else {
        return str;
    }
}

export function removeAccents(str: string) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function escapeParentheses(str: string) {
    return str.replace(/\(/g, '\\(').replace(/\)/g, '\\)');
}

export function formatPermissionString(str: string): string {
    const newStr = removeAccents(str);
    return newStr.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
}

export function generateAnchorId(str?: string, prefix?: string): string {
    if (!str) return '';

    const parsed = str
        .toLowerCase()
        .replace(/[^\w]+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');

    return prefix ? `${prefix}${parsed}` : parsed;
}

export function removeWhitespaces(str: string): string {
    return str.replace(/\s/g, '');
}

// This will take the ISO format date and convert it into MM-DD-YYYY format
export const formatDateISOtoDMY = (dateString: string, locale: LocalesEnum) => {
    const localeString = locale.toString().replace('_', '-');
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString(localeString, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
    return formattedDate;
};

// This function stringifies the object and then removes the keys' quotes
export function stringifyAndRemoveQuotesFromKeys(obj: object) {
    const cleaned = JSON.stringify(obj, null, 2);

    return cleaned.replace(/^[\t ]*"[^:\n\r]+(?<!\\)":/gm, function (match) {
        return match.replace(/"/g, '');
    });
}

// This function stringifies the object queries where the graphQL query is sent on the body as text
// Since the API doesn't accept the quotes on ENUM values, this function removes them
// The ENUM values are hardcoded since they are known
export function stringifyObjectForGraphQL(obj: object) {
    const stringifiedQuery = stringifyAndRemoveQuotesFromKeys(obj);
    const unquotedQueryEnums = stringifiedQuery.replace(/"UNIT"|"HIGH"|"MEDIUM"|"LOW"/g, (match) =>
        match.replace(/"/g, ''),
    );

    return unquotedQueryEnums;
}

type SplitParts = Array<{ id: number; text: string; isMatch: boolean }>;

export function splitStringOnMatch(str: string, pattern: string): SplitParts {
    const cleanStr = removeAccents(str);
    const cleanPattern = removeAccents(pattern);

    const regex = new RegExp(`(${cleanPattern})`, 'gi');

    const firstIndex = regex.exec(cleanStr)?.index ?? -1;
    const lastIndex = firstIndex + cleanPattern.length;

    return [
        { id: 0, text: str.slice(0, firstIndex), isMatch: false },
        { id: 1, text: str.slice(firstIndex, lastIndex), isMatch: true },
        { id: 2, text: str.slice(lastIndex), isMatch: false },
    ];
}

export function splitStringOnAllMatches(str: string, pattern: string): SplitParts {
    const cleanStr = removeAccents(str);
    const cleanPattern = removeAccents(pattern);

    const regex = new RegExp(`(${escapeParentheses(cleanPattern)})`, 'gi');

    const returnVal = [];
    let idCounter = 0;
    while (regex.lastIndex < cleanStr.length) {
        const startIndex = regex.lastIndex;
        const firstIndex = regex.exec(cleanStr)?.index ?? -1;
        if (firstIndex === -1) {
            returnVal.push({ id: idCounter++, text: str.slice(startIndex), isMatch: false });
            break;
        }

        const lastIndex = firstIndex + cleanPattern.length;

        returnVal.push({ id: idCounter++, text: str.slice(startIndex, firstIndex), isMatch: false });
        returnVal.push({ id: idCounter++, text: str.slice(firstIndex, lastIndex), isMatch: true });
    }

    return returnVal;
}

export function replaceWhitespaceWithNonBreakingSpace(str: string) {
    return str.replace(/\s/g, '\u00A0');
}

export function formatTestIds(id?: ID) {
    if (!id) return '';

    return id
        .toString()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join('');
}

export function formatNumberByLocale(number: number, locale: LocalesEnum): string {
    return number.toLocaleString(locale.substring(3, 5), {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
}
