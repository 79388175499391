import { useCallback, useState } from 'react';

import { paths } from '@core/api/api';
import { useLocale } from '@core/utils/locale';
import useFetch, { Config } from '@hooks/useFetch';
import { ExportPhrasesEnum } from '@core/enums/localeEnum';
import { OperatorFilter } from '@graphql/generated/graphql';
import { MethodsEnum, OperationsEnum } from '@core/enums/api';
import { stringifyObjectForGraphQL } from '@core/utils/string';
import { buildQuery, getExportTimestamp } from '@core/models/Export';
import { FeatureFlags, useFeatureFlag } from '@core/hooks/useFeatureFlag';
import { MIMETypes, downloadExport, getFileExtensionFromMimeType } from '@core/utils/file';

const POST_EXPORTS = '/export';

type GetExportEndpoint = paths[typeof POST_EXPORTS][MethodsEnum.POST];
type ExportParams = GetExportEndpoint[OperationsEnum.PARAMETERS]['query'];

export const DEFAULT_EXPORT_PARAMS: ExportParams = {
    format: 'CSV',
    delimiter: 'COMMA',
};

export default function useExportOperators() {
    const [hasExported, setHasExported] = useState(false);
    const {
        locale,
        t
    } = useLocale();

    const isTagsExportEnabled = useFeatureFlag(FeatureFlags.TagsExport);

    const {
        cancel,
        data,
        loading,
        error,
        doFetch
    } = useFetch<BlobPart>({
        lazy: true,
        url: POST_EXPORTS,
        method: MethodsEnum.POST,
        params: DEFAULT_EXPORT_PARAMS,
        initialConfig: {
            maxBodyLength: Infinity,
            headers: {
                'Content-Type': 'text/plain',
            },
        },
    });

    const getExportFallbackFilename = useCallback(
        (exportFormat: MIMETypes) => {
            const fileExension = getFileExtensionFromMimeType(exportFormat);

            return `${t(ExportPhrasesEnum.ExportFileName)}_${getExportTimestamp()}.${fileExension}`;
        },
        [t],
    );

    const getApiConfigFromExportType = useCallback((exportType: MIMETypes): Config<ExportParams> => {
        const params: ExportParams = { format: exportType === MIMETypes.XLSX ? 'EXCEL' : 'CSV' };

        return {
            params,
            responseType: exportType === MIMETypes.XLSX ? 'arraybuffer' : undefined,
        };
    }, []);

    const doFetchAndDownload = useCallback(
        (filters: OperatorFilter, exportType: MIMETypes) => {
            setHasExported(false);
            const gqlQuery = stringifyObjectForGraphQL(filters);
            const config = getApiConfigFromExportType(exportType);
            const filename = getExportFallbackFilename(exportType);

            doFetch({
                data: buildQuery(gqlQuery, locale, t, isTagsExportEnabled),
                ...config,
            })
                .then((res) => downloadExport(res, exportType, filename))
                .finally(() => setHasExported(true));
        },
        [getApiConfigFromExportType, getExportFallbackFilename, doFetch, locale, t, isTagsExportEnabled],
    );

    return {
        data,
        doFetchAndDownload,
        error,
        hasExported,
        isExportLoading: loading,
        onCancelExport: cancel,
    } as const;
}
